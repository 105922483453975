import React, { Component }  from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import axios from 'axios';
import './Login.css';
import ServiceAllApiData from '../service/ServiceAllApiData';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

//joe.soap@csiraq-ctiep.co.za
//SoapJoe1234!

//jane@soap.com
//Password12
//8751bdfd839e6722b806df5bda780fba07a755d4

export class Log extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mUsername: '',
            mPassword: '',
            reportsBusy: false
        };
        this.productServiceDataSources = new ServiceAllApiData();
    }

    showSuccess() {
        this.toast.show({severity:'success', summary: 'Success Message', detail:'Successfully generated the required reports!', life: 5000});
    }

    showError() {
        this.toast.show({severity:'error', summary: 'Error Message', detail:'Unsuccessful in generating the required reports!', life: 5000});
    }

    isProgressBar() {
        if(this.state.reportsBusy === true) {
            return (
                <React.Fragment>
                    <span className="guest-sign-in">Signing in to CTIEP</span>
                    <ProgressSpinner/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <span className="guest-sign-in">Sign in to CTIEP</span>
                </React.Fragment>
            );
        }
    }

    async nextPath(path) {
        const login = {'username': this.state.mUsername, 'password': this.state.mPassword};
        this.setState({reportsBusy: true});
        this.productServiceDataSources.doLogin(login, this.state.mUsername);
        setTimeout(() => {
            if(this.productServiceDataSources.getLoginResult() === "success")
            {
                this.setState({reportsBusy: false});
                this.showSuccess();
                this.props.history.push({
                    pathname: path
                });
            } else {
                this.setState({reportsBusy: false});
                this.showError();
            }
        }, 20000);
    }

    render() {
        return (
            <div className="login-body">
                <Toast ref={(el) => this.toast = el} />
                <Toast ref={(el) => this.toastTL = el} position="top-left" />
                <Toast ref={(el) => this.toastBL = el} position="bottom-left" />
                <Toast ref={(el) => this.toastBR = el} position="bottom-right" />
                <Toast ref={(el) => this.toastBC = el} position="bottom-center" />
                <div className="login-hero"></div>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-3" style={{ textAlign: 'left' }}>
                                <img src="assets/layout/images/login/icon-login.svg" alt="avalon-layout" />
                            </div>
                            <div className="p-col-9" style={{ textAlign: 'left' }}>
                                <h2 className="welcome-text">Successful Registration - Login Below</h2>
                                {this.isProgressBar()}
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">Username</label>
                                <div className="login-input">
                                    <InputText placeholder="username" value={this.state.mUsername} onChange={(e) => this.setState({mUsername: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12" style={{ textAlign: 'left' }}>
                                <label className="login-label">Password</label>
                                <div className="login-input">
                                    <InputText type="password" placeholder="Password" value={this.state.mPassword} onChange={(e) => this.setState({mPassword: e.target.value})} />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 button-pane">
                                <Button label="Sign In" onClick={(e) => {
                                    e.preventDefault();
                                    this.nextPath('/dashboard')
                                }} />
                            </div>
                            <div className="p-col-12 p-md-6 link-pane">
                                <button className="p-link">Forgot Password?</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}
