import React from 'react';
import { useHistory, Route } from 'react-router-dom';
import classNames from 'classnames';
import { Notifications } from './components/Notifications';
import './AppTopbar.css';
import ServiceAllApiData from './service/ServiceAllApiData';

const AppTopbar = (props) => {

    const history = useHistory();

    const goDashboard = () => {
        history.push('/');
    };

    const routers = [
        { path: '/notification', component: Notifications }
    ]

    const goToNotifications = () => {
        routers.map((router, index) => {
            if (router.exact) {
                return <Route key={`router${index}`} path={router.path} exact component={router.component} />
            }

            return <Route key={`router${index}`} path={router.path} component={router.component} />
        })
    };

    const topbarItemsClassName = classNames('topbar-items fadeInDown', { 'topbar-items-visible': props.topbarMenuActive });
    const profileItemClassName = classNames('profile-item', { 'active-top-menu': props.activeTopbarItem === 'profile' });
    const productServiceDataSources = new ServiceAllApiData();

    return (
        <div className="topbar clearfix">
            <div className="topbar-left">
                <button className="p-link" onClick={goDashboard}>
                    <img alt="Logo" src="assets/layout/images/logo-dark.png"/>
                </button>
            </div>

            <div className="topbar-right">
                <button className="p-link" id="menu-button" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-angle-left"></i>
                </button>

                <button className="p-link" id="topbar-menu-button" onClick={props.onTopbarMenuButtonClick}>
                    <i className="pi pi-bars"></i>
                </button>
                <ul className={topbarItemsClassName}>
                    {
                        (props.profileMode === 'top' || props.horizontal) &&
                        (
                            <li className={profileItemClassName} onClick={(e) => props.onTopbarItemClick({ originalEvent: e, item: 'profile' })}>
                                <button className="p-link">
                                    <img alt="User" className="profile-image" src="assets/layout/images/avatar.png" />
                                    <span className="topbar-item-name">Isabel Lopez</span>
                                    <span className="topbar-item-role">Marketing</span>
                                </button>
                                <ul className="layout-menu fadeInDown">
                                    <li role="menuitem">
                                        <button className="p-link">
                                            <i className="pi pi-fw pi-user"></i>
                                            <span>Profile</span>
                                        </button>
                                    </li>
                                    <li role="menuitem">
                                        <button className="p-link">
                                            <i className="pi pi-fw pi-lock"></i>
                                            <span>Privacy</span>
                                        </button>
                                    </li>
                                    <li role="menuitem">
                                        <button className="p-link">
                                            <i className="pi pi-fw pi-lock"></i>
                                            <span>Settings</span>
                                        </button>
                                    </li>
                                    <li role="menuitem">
                                        <button className="p-link">
                                            <i className="pi pi-fw pi-sign-out"></i>
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )
                    }

                    <li className={classNames({ 'active-top-menu': props.activeTopbarItem === 'logout' })} onClick={(e) => props.onTopbarItemClick({ originalEvent: e, item: 'logout' })}>
                        <button className="p-link">
                            <span className="topbar-item-role profile-text">{productServiceDataSources.useGlobalAdmin()}</span>
                            <img className="img-round" alt="User" src="assets/layout/images/avatar2.png" width="35"/>
                        </button>
                        <ul className="layout-menu fadeInDown">
                            <li role="menuitem">
                                <button className="p-link">
                                    <i className="pi pi-fw pi-sign-out"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li className={classNames({ 'active-top-menu': props.activeTopbarItem === 'messages' })} onClick={(e) => props.onTopbarItemClick({ originalEvent: e, item: 'messages' })}>
                        <button className="p-link">
                            <i className="topbar-icon pi pi-fw pi-envelope"></i>
                            <span className="topbar-badge">1</span>
                            <span className="topbar-item-name">Messages</span>
                        </button>
                        <ul className="layout-menu fadeInDown">
                            <li role="menuitem">
                                <button className="p-link topbar-message">
                                    <img alt="Avatar 2" src="assets/layout/images/avatar2.png" width="35" />
                                    <span>Static Test Message</span>
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li className={classNames({ 'active-top-menu': props.activeTopbarItem === 'notifications' })} onClick={goToNotifications}>
                        <button className="p-link" onClick={goToNotifications}>
                            <i className="topbar-icon pi pi-fw pi-bell"></i>
                            <span className="topbar-badge">1</span>
                            <span className="topbar-item-name">Notifications</span>
                        </button>
                    </li>

                    <li className={classNames('search-item', { 'active-top-menu': props.activeTopbarItem === 'search' })} onClick={(e) => props.onTopbarItemClick({ originalEvent: e, item: 'search' })}>
                        <div className="topbar-search">
                            <input type="text" placeholder="Search" />
                            <i className="pi pi-search"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AppTopbar;
